import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Swal from "sweetalert2";
import { MenuItem, TextField, Chip, Box, Typography } from "@mui/material";
import { List, ListItem } from "@mui/material";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function EditExam() {
  const [examData, setExamData] = useState({
    title: "",
    description: "",
    technologies: [],
    categories: [],
    copon: [],
    supports: [],
    price: "",
    attemptsAllowed: "",
    reservedPlaces: "",
    onSite: "",
    remote: "",
    isActive: false,
    inSlider: false,
    sumOfSite: "",
    mainlmg: null,
    prerequisites: [],
    objectifs: [],
    duration: "",
    language: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [technologies, setTechnologies] = useState([]);
  const [allCategories, setAllCategories] = useState([]); 
  const [categories, setCategories] = useState([]);
  const [allTechnologies, setAllTechnologies] = useState([]);
    const [allSupports, setAllSupports] = useState([]);
    const [allCopons, setAllCopons] = useState([]);
  const [supports, setSupports] = useState([]);
  const [copons, setCopons] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setExamData((prevData) => ({
        ...prevData,
        mainlmg: file,
      }));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setExamData({ ...examData, mainlmg: acceptedFiles[0] }); 
        },
    });
  

  useEffect(() => {
    const fetchData = async (endpoint, setter, labelKey, responseKey) => {
      try {
          const response = await axios.get(endpoint);
          const dataToFormat = response.data[responseKey] || []; 
          const formattedData = dataToFormat.map(item => ({ value: item._id, label: item[labelKey] }));
          setter(formattedData);
      } catch (error) {
          console.error(`Error fetching data from ${endpoint}:`, error);
      }
  };

    fetchData(`${process.env.REACT_APP_API_URL}/api/v2/category/categories`, setAllCategories, "name","data");
    fetchData(`${process.env.REACT_APP_API_URL}/api/v2/technology/technologies`, setAllTechnologies, "name","data");
    fetchData(`${process.env.REACT_APP_API_URL}/api/V2/slectronicsupport/all`, setAllSupports, "title","electronicSupports");
    fetchData(`${process.env.REACT_APP_API_URL}/api/v2/coupon/coupons`, setAllCopons, "code","data");

    const fetchExamData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/V2/exame/all-exame/${id}`);
            const exam = response.data.exame;

            const formatOptions = (items, labelKey) => items.map(item => ({ value: item._id, label: item[labelKey] }));

            setExamData({
              title: exam.title,
              description: exam.description,
              isActive: exam.isActive,
              inSlider: exam.inSlider,
              onSite: exam.onSite,
              remote: exam.remote,
              sumOfSite: exam.sumOfSite,
              prerequisites: exam.prerequisites || [],
              objectifs: exam.objectifs || [],
              price: exam.price,
              attemptsAllowed: exam.attemptsAllowed,
              reservedPlaces: exam.reservedPlaces,
              duration: exam.duration,
              language: exam.language,
              mainlmg: exam.mainlmg?.url || null,
                categories: formatOptions(exam.categories, "name"),
                technologies: formatOptions(exam.technologies, "name"),
                copon: formatOptions(exam.copon, "code"),
                supports: formatOptions(exam.supports, "title"),
            });
            setTechnologies(formatOptions(exam.technologies, "name"));
            setSupports(formatOptions(exam.supports, "title"));
            setCopons(formatOptions(exam.copon, "code"));


        } catch (error) {
          console.error("Error fetching exam data:", error.message);

        }
    };

    fetchExamData();
}, [id]);

  const updateExamData = (field, value) => {
    setExamData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

        for (const [key, value] of Object.entries(examData)) {
            if (key === "technologies" || key === "categories" || key === "supports" || key === "copon") {
                const valuesToSend = value.map(item => item.value);
                valuesToSend.forEach(val => formData.append(`${key}[]`, val));
            } else if (key !== "mainlmg") {
                formData.append(key, value);
            }
        }

        if (typeof examData.mainlmg !== 'string' && examData.mainlmg !== null) {
          formData.append("mainlmg", examData.mainlmg);
      }
  
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V2/exame/all-exame/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Exam Edited successfully!",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          // Navigate after user clicks OK
          navigate("/dashboard/2-exams/exams-page");
        });
      }
    } catch (error) {
      console.error("Error editing exam:", error.response?.data || error.message);
      
      Swal.fire({
        title: "Error!",
        text: error.response?.data?.message || "Failed to edit exam. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} textAlign="center">
        <MDTypography variant="h3" fontWeight="bold">
          Edit Exam
        </MDTypography>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          Modify the details of your Exam below.
        </MDTypography>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <MDBox mt={4}>
          <Card>
            <MDBox p={3}>
              <MDTypography variant="h5">Edit Exam</MDTypography>
              <MDBox mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      type="text"
                      label="Title"
                      value={examData.title}
                      onChange={(e) => updateExamData("title", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Description
                    </MDTypography>
                    <ReactQuill
                      value={examData.description}
                      onChange={(value) => updateExamData("description", value)}
                    />
                  </Grid>

                  {/* Technologiesies */}
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Technologies
                    </MDTypography>
                    <Select
                isMulti
                options={allTechnologies}
                value={examData.technologies}
                onChange={(selectedOptions) => updateExamData("technologies", selectedOptions)}
            />
                  </Grid>
                  {/* Categories */}
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Categories
                    </MDTypography>
                    <Select
                isMulti
                options={allCategories} 
                value={examData.categories}
                onChange={(selectedOptions) => updateExamData("categories", selectedOptions)}
            />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Support
                    </MDTypography>
                    <Select
                isMulti
                options={allSupports}
                value={examData.supports}
                onChange={(selectedOptions) => updateExamData("supports", selectedOptions)}
            />
                  </Grid>
                  {/* Copons */}
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Copons
                    </MDTypography>
                    <Select
                isMulti
                options={allCopons}
                value={examData.copon}
                onChange={(selectedOptions) => updateExamData("copon", selectedOptions)}
            />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      On Site
                    </MDTypography>
                    <Select
                      options={[{ label: "PersonVue", value: "personVue" }]}
                      value={{ label: examData.onSite, value: examData.onSite }}
                      onChange={(selectedOption) => updateExamData("onSite", selectedOption.value)}
                      placeholder="Select On Site"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Remote
                    </MDTypography>
                    <Select
                      options={[
                        { label: "On Vue", value: "onVue" },
                        { label: "Home", value: "home" },
                      ]}
                      value={{ label: examData.remote, value: examData.remote }}
                      onChange={(selectedOption) => updateExamData("remote", selectedOption.value)}
                      placeholder="Select Remote"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      language
                    </MDTypography>
                    <Select
                      options={[
                        { label: "English", value: "English" },
                        { label: "French", value: "French" },
                        { label: "Arabic", value: "Arabic" },
                        { label: "Spanish", value: "Spanish" },
                        { label: "German", value: "German" },
                      ]}
                      value={{ label: examData.language, value: examData.language }}
                      onChange={(selectedOption) =>
                        updateExamData("language", selectedOption.value)
                      }
                      placeholder="Select Language"
                    />
                  </Grid>
                  {/* Price, Attempts, Reserved Places */}
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="number"
                      label="Price"
                      value={examData.price}
                      onChange={(e) => updateExamData("price", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="number"
                      label="Attempts Allowed"
                      value={examData.attemptsAllowed}
                      onChange={(e) => updateExamData("attemptsAllowed", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="number"
                      label="Reserved Places"
                      value={examData.reservedPlaces}
                      onChange={(e) => updateExamData("reservedPlaces", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type="number"
                      label="Sum of Site"
                      value={examData.sumOfSite}
                      onChange={(e) => updateExamData("sumOfSite", e.target.value)}
                    />
                  </Grid>
                  {/* Other Fields */}
                  <Grid item xs={12} sm={6}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Duration
                    </MDTypography>
                    <MDInput
                      value={examData.duration}
                      onChange={(e) => updateExamData("duration", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Active
                    </MDTypography>
                    <Switch
                      checked={examData.isActive}
                      onChange={(e) => updateExamData("isActive", e.target.checked)}
                      color="primary"
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      In Slider
                    </MDTypography>
                    <Switch
                      checked={examData.inSlider}
                      onChange={(e) => updateExamData("inSlider", e.target.checked)}
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                  <Box mt={2}>
                    <Typography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Prerequisites
                    </Typography>
                    <List>
                      {examData.prerequisites?.map((prerequisite, index) => (
                        <ListItem key={index} disablePadding>
                          <Chip
                            label={prerequisite}
                            onDelete={() =>
                              updateExamData(
                                "prerequisites",
                                examData.prerequisites.filter((prereq) => prereq !== prerequisite)
                              )
                            }
                            color="primary"
                            sx={{ margin: 0.8 }}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <TextField
                      fullWidth
                      label="Add Prerequisites"
                      value={inputValue2}
                      onChange={(e) => setInputValue2(e.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && inputValue2.trim() !== "") {
                          updateExamData("prerequisites", [
                            ...(examData.prerequisites || []),
                            inputValue2.trim(),
                          ]);
                          setInputValue2("");
                        }
                      }}
                      placeholder="Press Enter to add a prerequisite"
                      variant="outlined"
                      multiline
                      rows={3}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box mt={2}>
                    <Typography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Objectifs
                    </Typography>
                    <List>
                      {examData.objectifs?.map((objectif, index) => (
                        <ListItem key={index} disablePadding>
                          <Chip
                            label={objectif}
                            onDelete={() =>
                              updateExamData(
                                "objectifs",
                                examData.objectifs.filter((obj) => obj !== objectif)
                              )
                            }
                            color="primary"
                            sx={{ margin: 0.8 }}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <TextField
                      fullWidth
                      label="Add Objectifs"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && inputValue.trim() !== "") {
                          updateExamData("objectifs", [
                            ...(examData.objectifs || []),
                            inputValue.trim(),
                          ]);
                          setInputValue("");
                        }
                      }}
                      placeholder="Press Enter to add an objectif"
                      variant="outlined"
                      multiline
                      rows={3}
                    />
                  </Box>
                </Grid>

                  {/* Image */}
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                      <MDTypography
                        component="label"
                        variant="h5"
                        fontWeight="bold"
                        color="primary"
                        textAlign="center"
                        mb={2}
                      >
                        Exam Image
                      </MDTypography>
                    </Grid>
                    {examData.mainlmg && (
                      <Grid container justifyContent="center" alignItems="center" mb={4}>
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "220px",
                            height: "auto",
                            borderRadius: "16px",
                            overflow: "hidden",
                            boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
                            transition: "transform 0.3s ease, box-shadow 0.3s ease",
                            cursor: "pointer",
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.transform = "scale(1.05)";
                            e.currentTarget.style.boxShadow = "0 12px 20px rgba(0, 0, 0, 0.3)";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.transform = "scale(1)";
                            e.currentTarget.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.2)";
                          }}
                          onClick={() => {
                            document.getElementById("examFileInput").click();
                          }}
                        >
                          <img
                            src={
                              typeof examData.mainlmg === "string"
                                ? examData.mainlmg
                                : URL.createObjectURL(examData.mainlmg)
                            }
                            alt="Exam"
                            style={{
                              width: "100%",
                              height: "auto",
                              borderRadius: "16px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </Grid>
                    )}
                    <input
                      id="examFileInput"
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          const newImage = e.target.files[0];
                          setExamData({ ...examData, mainlmg: newImage });
                        }
                      }}
                    />
                    <MDBox
                      {...getRootProps()}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      border="2px dashed"
                      borderRadius="12px"
                      borderColor="grey.400"
                      bgcolor="grey.100"
                      py={4}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "grey.200",
                        },
                        transition: "background-color 0.2s ease-in-out",
                      }}
                    >
                      <input {...getInputProps()} />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="textSecondary"
                        mb={1}
                        textAlign="center"
                      >
                        Drag & drop an image here or
                      </MDTypography>
                      <MDButton
                        variant="contained"
                        color="primary"
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        Browse Files
                      </MDButton>
                    </MDBox>
                  </Grid>

                  
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
          <MDBox mt={4} display="flex" justifyContent="flex-end">
            <MDButton variant="contained" color="primary" onClick={handleSubmit}>
              Update Exam
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
EditExam.propTypes = {
  examData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    technologies: PropTypes.arrayOf(PropTypes.string),
    categories: PropTypes.arrayOf(PropTypes.string),
    copon: PropTypes.arrayOf(PropTypes.string),
    supports: PropTypes.arrayOf(PropTypes.string),
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attemptsAllowed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reservedPlaces: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSite: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    remote: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    isActive: PropTypes.bool,
    inSlider: PropTypes.bool,
    sumOfSite: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mainlmg: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    language: PropTypes.string,
  }),
  updateExamData: PropTypes.func,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  technologies: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  supports: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  copons: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default EditExam;
